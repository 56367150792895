
// export const PUBLIC_URL = 'http://127.0.0.1:8000';
// export const PUBLIC_URL = 'http://localhost:8000';
// http://buyforless.prompttechsolutionshosting.com/


export const PUBLIC_URL = 'https://pt-admin-multivendor.prompttechdemohosting.com/api/webapp'; // now
// export const PUBLIC_URL = 'http://127.0.0.1:8000/api/webapp';
export const ENCRYPTKEY = 'buy@for#less&user';

// let bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTKEY);
// let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

export const CLIENTID = 'ID-29598029';
export const SECRET = 'SECRET-966207E6-797D-4ADD-9E8B-CF5365D773B0';
export const PAYMENTURL = 'https://testapi.vaultspay.com/public/external/v1/merchant-auth';