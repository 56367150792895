import React from 'react';
import { Link } from 'react-router-dom';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
const BannerWithDeal = (props) => {

    let { banner } = props;

    return (
        <section className="banner-row irf">
            <div className="container">
                <div className="row">
                    {banner && banner.map((item, index) => {
                        if(item.type === 'category'){
                            return (
                                <div className="col-12 col-lg-4 col-md-6" key={index}>
                                    <div className="position-relative">
                                        <img className="img-fluid hover-zoom" src={item.image} alt={item.title} />
                                        <div className="banner-text">
                                            <h6 className="text-white">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h6>
                                            <h3 className="text-white" style={{ width: '50%' }}>{lang=='en'?item.description:item.multi_description? item.multi_description.arabic :item.description}</h3>
                                            <Link className="more-link text-dark" to={`/product-grid?category=${item.cat_id}&innersubcategory=&subcategory=&category_name=${item.category ? item.category.category_name : ''}`}>{languageList['Shop_now'][lang]} </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        else if(item.type === 'product'){
                            return (
                                <div className="col-12 col-lg-4 col-md-6" key={index}>
                                    <div className="position-relative">
                                        <img className="img-fluid hover-zoom" src={item.image} alt={item.title} />
                                        <div className="banner-text">
                                            <h6 className="text-white">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h6>
                                            <h3 className="text-white" style={{ width: '50%' }}>{lang=='en'?item.description:item.multi_description? item.multi_description.arabic :item.description}</h3>
                                            <Link className="more-link text-dark" to={`/product-details?product=${item.product_id}`}>{languageList['Shop_now'][lang]} </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        else{
                            return (
                                <div className="col-12 col-lg-4 col-md-6" key={index}>
                                    <div className="position-relative">
                                        <img className="img-fluid hover-zoom" src={item.image} alt={item.title} />
                                        <div className="banner-text">
                                            <h6 className="text-white">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h6>
                                            <h3 className="text-white" style={{ width: '50%' }}>{lang=='en'?item.description:item.multi_description? item.multi_description.arabic :item.description}</h3>
                                            {/* <a className="more-link text-dark" href="javascript:void(0);">Shop Now </a> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}

                </div>
            </div>
        </section>
    );
};

export default BannerWithDeal;