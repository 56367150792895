import React, { useEffect, useState } from 'react';
import Footer from '../../common/layout/Footer';
import Header from '../../common/layout/Header';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import LeftBar from '../LeftBar';
import PaginationLink from '../PaginationLink';
import Recommended from '../Recommended';
import Banner from '../Banner';
import BreadCrumb from '../BreadCrumb';
import ProductItem from './ProductItem';
import TopBar from '../TopBar';
import { useLocation, useParams } from 'react-router';
import axios from 'axios';
import { PUBLIC_URL } from '../../common/CommonString';
import { languageList } from '../../../languageHelper';

let ciphertext = localStorage.getItem('user_token') ? JSON.stringify(localStorage.getItem('user_token')) : '';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const Index = (prop) => {
    let factoryOutlet_prop=(prop.hasOwnProperty('factoryOutlet'))?prop.factoryOutlet:0;
    const [factoryOutlet,setFactoryOutlet]=useState(factoryOutlet_prop);
    const [product, setProduct] = useState([]);
    const [links, setLinks] = useState({
        current_page: '',
        first_page_url: '',
        from: '',
        last_page_url: '',
        next_page_url: '',
        path: '',
        per_page: '',
        prev_page_url: '',
        to: '',
        total: '',
    });

    const [firstLoad, setFirstLoad] = useState(true);
    const [cartAction, setCartAction] = useState(false);
    const [oldCategory, setOldCategory] = useState('');
    const [oldSubCategory, setOldSubCategory] = useState('');
    const [oldInnSubCategory, setOldInnSubCategory] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [oldSearch, setOldSearch] = useState('');
    const [mainBanner, setMainBanner] = useState([]);
    const [sideBanner, setSideBanner] = useState([]);
    const [perPage, setPerPage] = useState(12);

    const location = useLocation();
    const param = useParams();
  const changeFactoryOutlet=(st)=>{
       setFactoryOutlet(st);
   }
    useEffect(() => {

        let category = ((new URLSearchParams(location.search).get('category')) != '') ? (new URLSearchParams(location.search).get('category')) : '';
        let subcategory = ((new URLSearchParams(location.search).get('subcategory')) != '') ? (new URLSearchParams(location.search).get('subcategory')) : '';
        let innersubcategory = ((new URLSearchParams(location.search).get('innersubcategory')) != '') ? (new URLSearchParams(location.search).get('innersubcategory')) : '';
        let category_name = ((new URLSearchParams(location.search).get('category_name')) != '') ? (new URLSearchParams(location.search).get('category_name')) : '';

        let search = ((new URLSearchParams(location.search).get('search')) != '') ? (new URLSearchParams(location.search).get('search')) : '';
//        let factory_outlet = ((new URLSearchParams(location.search).get('factory_outlet')) != '') ? (new URLSearchParams(location.search).get('factory_outlet')) : 0;
        if (firstLoad == true) {

            setOldCategory(category);
            setOldSubCategory(subcategory);
            setOldInnSubCategory(innersubcategory);
           
            if (typeof (search) === 'string' && search !== '') {
                
                setOldSearch(search);

                let formData = new FormData();
                formData.append('search', search);
                formData.append('user_id', 1);
                formData.append('perpage', perPage);
                formData.append('factoryOutlet', factoryOutlet);

                axios({
                    url: `${PUBLIC_URL}/searchproducts`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + ciphertext },
                    data: formData,
                }).then(response => {

                    if (response.data.status == 1) {
                        // console.log(1);

                        setProduct(response.data.data.data);
                        setLinks({
                            current_page: response.data.data.current_page,
                            first_page_url: response.data.data.first_page_url,
                            from: response.data.data.from,
                            last_page_url: response.data.data.last_page_url,
                            next_page_url: response.data.data.next_page_url,
                            path: response.data.data.path,
                            per_page: response.data.data.per_page,
                            prev_page_url: response.data.data.prev_page_url,
                            to: response.data.data.to,
                            total: response.data.data.total,
                        });
                    }

                }).catch((error) => { });
            }
            else {
                
                let formData = new FormData();
                formData.append('user_id', 1);
                formData.append('perpage', perPage);
                formData.append('factoryOutlet', factoryOutlet);
                if(location !== '' && location.search !== ''){
                        
                    if (category != '' || category != null)
                        formData.append('category_id', category);
                    if (subcategory != '' || subcategory != null)
                        formData.append('subcategory_id', subcategory);
                    if (innersubcategory != '' || innersubcategory != null)
                        formData.append('innersubcategory_id', innersubcategory);

                    setCategoryName(category_name);

                }
                
                axios({
                    url: `${PUBLIC_URL}/products`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + ciphertext },
                    data: formData,

                }).then(response => {

                    if (response.data.status == 1) {
                        // console.log(2);
                        // console.log(response.data.data.data);
                        setProduct(response.data.data.data);
                        setLinks({
                            current_page: response.data.data.current_page,
                            first_page_url: response.data.data.first_page_url,
                            from: response.data.data.from,
                            last_page_url: response.data.data.last_page_url,
                            next_page_url: response.data.data.next_page_url,
                            path: response.data.data.path,
                            per_page: response.data.data.per_page,
                            prev_page_url: response.data.data.prev_page_url,
                            to: response.data.data.to,
                            total: response.data.data.total,
                        });
                    }

                }).catch((error) => { });
            }

            let formNewData = new FormData();
            formNewData.append('category_id', typeof(category) === 'string' && category !== '' ? category : '');

            axios({
                url: `${PUBLIC_URL}/categorybanner`,
                method: 'POST',
                data: formNewData

            }).then(response => {

                if (response.data.status == 1) {

                    setMainBanner(response.data.mainbanner);
                    setSideBanner(response.data.leftbanner);
                }

            }).catch((error) => { });
            
        }

        if ((typeof (search) === 'string' && search != oldSearch) && firstLoad == false) {

            setOldSearch(search);

            let formData = new FormData();
            formData.append('search', search);
            formData.append('user_id', 1);
            formData.append('perpage', perPage);
            formData.append('factoryOutlet', factoryOutlet);
            axios({
                url: `${PUBLIC_URL}/searchproducts`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {
                    
                    setProduct(response.data.data.data);
                    setLinks({
                        current_page: response.data.data.current_page,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total,
                    });
                    window.scrollTo(0, 0);
                }

            }).catch((error) => { });

        }

        if ((category != oldCategory || subcategory != oldSubCategory || innersubcategory != oldInnSubCategory) && firstLoad == false) {

            setOldCategory(category);
            setOldSubCategory(subcategory);
            setOldInnSubCategory(innersubcategory);

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('perpage', perPage);
             formData.append('factoryOutlet', factoryOutlet);
            if (location !== '' && location.search !== '') {

                if (category != '' || category != null)
                    formData.append('category_id', category);
                if (subcategory != '' || subcategory != null)
                    formData.append('subcategory_id', subcategory);
                if (innersubcategory != '' || innersubcategory != null)
                    formData.append('innersubcategory_id', innersubcategory);

                setCategoryName(category_name);
            }

            axios({
                url: `${PUBLIC_URL}/products`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    setProduct(response.data.data.data);
                    setLinks({
                        current_page: response.data.data.current_page,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total,
                    });
                    window.scrollTo(0, 0);
                }

            }).catch((error) => { });
        }
        setFirstLoad(false);

    });

    const cartChange = () => {
        setCartAction(true);
    }

    const recallProduct = () => {
        
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('category_id', typeof(oldCategory) === 'string' && oldCategory !== '' ? oldCategory : '');
        formData.append('subcategory_id', typeof(oldSubCategory) === 'string' && oldSubCategory !== '' ? oldSubCategory : '');
        formData.append('innersubcategory_id', typeof(oldInnSubCategory) === 'string' && oldInnSubCategory !== '' ? oldInnSubCategory : '');
        formData.append('perpage', perPage);

        axios({
            url: `${PUBLIC_URL}/products`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                setProduct(response.data.data.data);
                setLinks({
                    current_page: response.data.data.current_page,
                    first_page_url: response.data.data.first_page_url,
                    from: response.data.data.from,
                    last_page_url: response.data.data.last_page_url,
                    next_page_url: response.data.data.next_page_url,
                    path: response.data.data.path,
                    per_page: response.data.data.per_page,
                    prev_page_url: response.data.data.prev_page_url,
                    to: response.data.data.to,
                    total: response.data.data.total,
                });
                window.scrollTo(0, 0);
            }

        }).catch((error) => { });

        if (typeof (oldSearch) === 'string' && oldSearch !== '') {

            let formData = new FormData();
            formData.append('search', oldSearch);
            formData.append('user_id', 1);
            formData.append('perpage', perPage);
            formData.append('factoryOutlet', factoryOutlet);
            axios({
                url: `${PUBLIC_URL}/searchproducts`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {

                    setProduct(response.data.data.data);
                    setLinks({
                        current_page: response.data.data.current_page,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total,
                    });
                }
                window.scrollTo(0, 0);
            }).catch((error) => { });
        }

    }

    const productFilter = (filter) => {

        let formData = new FormData();
        formData.append('user_id', 1);
        
        filter.brandId.forEach(item => {
            formData.append('brand[]', item);
        });
        
        filter.ratting.forEach(item => {
            formData.append('ratting[]', item);
        });
        
        formData.append('type', filter.type);
        formData.append('max', filter.priceTo);
        formData.append('min', filter.priceFrom);
        formData.append('perpage', perPage);
        formData.append('category_id', oldCategory ? oldCategory : '');
        formData.append('subcategory_id', oldSubCategory ? oldSubCategory : '');
        formData.append('innersubcategory_id', oldInnSubCategory ? oldInnSubCategory : '');

        axios({
            url: `${PUBLIC_URL}/filter`,
            method: 'POST',
            data: formData,
        }).then(response => {

            if (response.data.status == 1) {
                setProduct(response.data.data.data);
                setLinks({
                    current_page: response.data.data.current_page,
                    first_page_url: response.data.data.first_page_url,
                    from: response.data.data.from,
                    last_page_url: response.data.data.last_page_url,
                    next_page_url: response.data.data.next_page_url,
                    path: response.data.data.path,
                    per_page: response.data.data.per_page,
                    prev_page_url: response.data.data.prev_page_url,
                    to: response.data.data.to,
                    total: response.data.data.total,
                });
            }
            window.scrollTo(0, 0);
        }).catch((error) => { });
    }

    const pageUpdate = (url) => {

        let formData = new FormData();
        formData.append('search', oldSearch ? oldSearch : '');
        formData.append('user_id', 1);
        formData.append('category_id', oldCategory ? oldCategory : '');
        formData.append('subcategory_id', oldSubCategory ? oldSubCategory : '');
        formData.append('innersubcategory_id', oldInnSubCategory ? oldInnSubCategory : '');
        formData.append('perpage', perPage);

        axios({
            url: `${url}`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,
        }).then(response => {

            if (response.data.status == 1) {

                setProduct(response.data.data.data);
                setLinks({
                    current_page: response.data.data.current_page,
                    first_page_url: response.data.data.first_page_url,
                    from: response.data.data.from,
                    last_page_url: response.data.data.last_page_url,
                    next_page_url: response.data.data.next_page_url,
                    path: response.data.data.path,
                    per_page: response.data.data.per_page,
                    prev_page_url: response.data.data.prev_page_url,
                    to: response.data.data.to,
                    total: response.data.data.total,
                });
                window.scrollTo(0, 0);
            }

        }).catch((error) => { });
    }

    const perPageUpdate = (count) => {

        setPerPage(count);

        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('category_id', typeof(oldCategory) === 'string' && oldCategory !== '' ? oldCategory : '');
        formData.append('subcategory_id', typeof(oldSubCategory) === 'string' && oldSubCategory !== '' ? oldSubCategory : '');
        formData.append('innersubcategory_id', typeof(oldInnSubCategory) === 'string' && oldInnSubCategory !== '' ? oldInnSubCategory : '');
        formData.append('perpage', count);

        axios({
            url: `${PUBLIC_URL}/products`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                setProduct(response.data.data.data);
                setLinks({
                    current_page: response.data.data.current_page,
                    first_page_url: response.data.data.first_page_url,
                    from: response.data.data.from,
                    last_page_url: response.data.data.last_page_url,
                    next_page_url: response.data.data.next_page_url,
                    path: response.data.data.path,
                    per_page: response.data.data.per_page,
                    prev_page_url: response.data.data.prev_page_url,
                    to: response.data.data.to,
                    total: response.data.data.total,
                });
                window.scrollTo(0, 0);
            }

        }).catch((error) => { });

        if (typeof (oldSearch) === 'string' && oldSearch !== '') {

            let formData = new FormData();
            formData.append('search', oldSearch);
            formData.append('user_id', 1);
            formData.append('perpage', count);

            axios({
                url: `${PUBLIC_URL}/searchproducts`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {

                    setProduct(response.data.data.data);
                    setLinks({
                        current_page: response.data.data.current_page,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total,
                    });
                    window.scrollTo(0, 0);
                }

            }).catch((error) => { });
        }

    }

    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header cartAction={cartAction} factoryOutlet={factoryOutlet}/>
                {/* <!-- end header --> */}

                <section className="main-container col2-left-layout">
                    <div className="container">
                        {/* <!-- Breadcrumbs --> */}
                        <BreadCrumb title={languageList['Product_List'][lang]} />
                        {/* <!-- Breadcrumbs End --> */}
                        <div className="row">
                            <div className="col-sm-9 col-sm-push-3">
                                <Banner banner={mainBanner} />

                                <div className="page-title">
                                    <h2> {(factoryOutlet==1)?"Factory Outlet /":""}{categoryName ? categoryName : 'All Products'} </h2>
                                    <p className="font-size-14 text-gray-90 mb-0">Showing {links.total} results</p>
                                </div>

                                <article className="col-main">
                                    <div className="pro-listing">
                                        <TopBar productFilter={productFilter} perPage={perPageUpdate} madeActive="grid" factoryOutlet={factoryOutlet}/>

                                        <div className="category-products">
                                            <ul className="products-grid">
                                                {product && product.length == 0 ? <div className="text-center">No Data Found!</div> : ''}
                                                {product && product.map((item, index) => {

                                                    return <ProductItem iteration={index} product={item} cartEvent={cartChange} />
                                                })}
                                            </ul>
                                        </div>

                                        {links.total / perPage >= 1 ? <PaginationLink links={links} pageUpdate={pageUpdate} /> : ''}

                                        <br />
                                    </div>
                                </article>

                                {/* ======    End article  ========= */}
                            </div>

                            <LeftBar factoryOutlet={factoryOutlet} productFilter={productFilter} resetProps={recallProduct} type="grid" banner={sideBanner}/>

                        </div>
                    </div>

                    {/* <Recommended /> */}

                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;