import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const TopBar = (props) => {

    const location = useLocation();

    let search = location.search;
    const filterChange = (e) => {
        
        props.productFilter({

            brandId: [],
            ratting: [],
            priceFrom: '',
            priceTo: '',
            type: e.target.value,
        });
    }

    const countChange = (e) => {

        props.perPage(e.target.value);

    }

    let madeActive = props.madeActive;
    console.log("out "+props.factoryOutlet);
    return (
        <>
            <div className="toolbar">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-4 col-md-4 col-3">

                        <div className="product-list-grid">
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link to={`/product-grid${search}`} className={`button-grid nav-link ${madeActive == 'grid' ? 'active' : ''}`}>
                                        <i className="fa fa-th-large"></i>
                                    </Link>
                                </li>
                                {(props.factoryOutlet!=='1')?(
                                <li className="nav-item">
                                    <Link to={`/product-list${search}`} className={`button-grid nav-link ${madeActive == 'list' ? 'active' : ''}`}>
                                        <i className="fa fa-bars"></i>
                                    </Link>
                                </li>):''}

                            </ul>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-sm-8 col-md-8 col-9">
                        <div className="pro-sorting">
                            <form className="woocommerce-showing">
                                <select name="showby" aria-label="show" onChange={countChange} className="show-filter">
                                    <option value="12" selected="selected">Show 12</option>
                                    <option value="24">Show 24</option>
                                    <option value="36">Show 36</option>
                                    <option value="48">Show 48</option>
                                    <option value="60">Show 60</option>
                                </select>
                            </form>
                        </div>
                        <div className="pro-sorting">
                            <form className="woocommerce-ordering">
                                <select name="orderby" aria-label="Shop order" onChange={filterChange}>
                                    <option value="">Sort by </option>
                                    <option value="sort-by-popularity">Sort by popularity</option>
                                    {/* <option value="sort-by-rating">Sort by average rating</option> */}
                                    <option value="sort-by-date">Sort by latest</option>
                                    <option value="price-low-to-high">Sort by price: low to high</option>
                                    <option value="price-high-to-low">Sort by price: high to low</option>
                                </select>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopBar;