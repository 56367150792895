import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ENCRYPTKEY, PUBLIC_URL } from '../CommonString';
import SpinnerComponent from '../SpinnerComponent';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import MiniCart from './MiniCart';
import Register from './Register';
// import DownloadMobile from './DownloadMobile';
import { withRouter } from './withRouter';
import { MobileView} from 'react-device-detect';
import { languageList } from '../../../languageHelper';
var jQuery = require("jquery");
const CryptoJS = require("crypto-js");

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
let name = localStorage.getItem('user_name') ? localStorage.getItem('user_name') : '';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logedUser: false,
            cart: [],
            category: [],
            search: '',
            loading: false,
            language: lang
        }
    }

    componentDidMount = () => {

        const script1 = document.createElement("script");
        script1.src = "/js/jquery-3.2.1.min.js";
        script1.async = true;

        const script2 = document.createElement("script");
        script2.src = "/js/bootstrap.min.js";
        script2.async = true;

        const script3 = document.createElement("script");
        script3.src = "/js/revslider.js";
        script3.async = true;

        const script5 = document.createElement("script");
        script5.src = "/js/owl.carousel.min.js";
        script5.async = true;

        const script4 = document.createElement("script");
        script4.src = "/js/common.js";
        script4.async = true;

        const script6 = document.createElement("script");
        script6.src = "/js/jquery.mobile-menu.min.js";
        script6.async = true;

        const script7 = document.createElement("script");
        script7.src = "/js/countdown.js";
        script7.async = true;

        const script8 = document.createElement("script");
        script8.src = "/js/cloud-zoom.js";
        script8.async = true;
        

        const style1 = document.createElement('link');
        style1.rel = "stylesheet";
        style1.type = "text/css";
        style1.href = "/css/bootstrap.min.css";

        const style2 = document.createElement('link');
        style2.rel = "stylesheet";
        style2.type = "text/css";
        style2.href = "/css/font-awesome.min.css";

        const style3 = document.createElement('link');
        style3.rel = "stylesheet";
        style3.type = "text/css";
        style3.href = "/css/simple-line-icons.css";

        const style4 = document.createElement('link');
        style4.rel = "stylesheet";
        style4.type = "text/css";
        style4.href = "/css/owl.carousel.css";

        const style5 = document.createElement('link');
        style5.rel = "stylesheet";
        style5.type = "text/css";
        style5.href = "/css/owl.theme.css";

        const style6 = document.createElement('link');
        style6.rel = "stylesheet";
        style6.type = "text/css";
        style6.href = "/css/jquery.bxslider.css";

        const style7 = document.createElement('link');
        style7.rel = "stylesheet";
        style7.type = "text/css";
        style7.href = "/css/flexslider.css";

        const style8 = document.createElement('link');
        style8.rel = "stylesheet";
        style8.type = "text/css";
        style8.href = "/css/jquery.mobile-menu.css";

        const style9 = document.createElement('link');
        style9.rel = "stylesheet";
        style9.type = "text/css";
        style9.href = "/css/style.css";

        const style10 = document.createElement('link');
        style10.rel = "stylesheet";
        style10.type = "text/css";
        style10.href = "/css/revslider.css";

        // document.body.appendChild(script1);
        // document.body.appendChild(script2);
        // document.body.appendChild(script3);

        // document.body.appendChild(script5);

        document.body.appendChild(script4);
        // document.body.appendChild(script6);
        // document.body.appendChild(script7);
        document.body.appendChild(script8);

        // document.head.appendChild(style1);
        // document.head.appendChild(style2);
        // document.head.appendChild(style3);
        // document.head.appendChild(style4);
        // document.head.appendChild(style5);
        // document.head.appendChild(style6);
        // document.head.appendChild(style7);
        // document.head.appendChild(style8);
        // document.head.appendChild(style9);
        // document.head.appendChild(style10);
        this.setState({
            loading: true,
        });

        if ((ciphertext !== '')&&(ciphertext !== 'null')) {

            this.setState({
                logedUser: true,
            });
                    axios({
            url: `${PUBLIC_URL}/getcart`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: {
                user_id: 1,
            }

        })
                .then(response => {

            if (response.data.status == 1) {

                this.setState({
                    cart: response.data.data,
                });
            }

        })
                .catch((error) => {

            if(error.response){
                localStorage.removeItem('user_token');
            }
        });
        }
        else {

            this.setState({
                logedUser: false,
            });
            localStorage.setItem('user_name', '');


               let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data=[];
                }
                this.setState({
                        cart: cart_data,
                    });
        }





        axios({
            url: `${PUBLIC_URL}/categoryWithSubcategory`,
            method: 'POST',

        }).then(response => {

            if (response.data.status == 1) {
                // console.log(response.data.category)
                this.setState({
                    category: response.data.category,
                    loading: false,
                });
            }

        }).catch((error) => {

            this.setState({
                loading: false,
            });
            
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        if (nextProps.cartAction == true) {
             if ((ciphertext !== '')&&(ciphertext !== 'null')) {
            axios({
                url: `${PUBLIC_URL}/getcart`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: {
                    user_id: 1,
                }

            })
                    .then(response => {

                if (response.data.status == 1) {

                    this.setState({
                        cart: response.data.data,
                    });
                }

            })
                    .catch((error) => { });
            }else
            {
                     let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data={'data':[]};
                }
                this.setState({
                        cart: cart_data,
                    });
            }
        }
    }

    logout = () => {

        this.setState({
            loading: true,
        });

        // console.log(ciphertext, '++++++++');
        // let bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTKEY);
        // console.log(bytes, '==========');
        // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log(bytes, decryptedData, '------------');

        axios({
            url: `${PUBLIC_URL}/logout`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
        }).then(response => {

            if (response.data.status == 1) {

                localStorage.removeItem('user_token');
                localStorage.removeItem('user_name');
                localStorage.removeItem('mycart');
                this.setState({
                    logedUser: false,
                });
               // window.location.reload(true);
               window.location='/';
            }

            this.setState({
                loading: false,
            });

        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });
    }

    cartAction = (id) => {

        
         let mycart = JSON.parse(localStorage.getItem('mycart'));
    if(!mycart)
    {
       mycart=[];
    }
        if ((ciphertext === '')||(ciphertext==='null'))
        {
            mycart.forEach((item,index)=>{
                if(item.product_id===id)
                {
                    mycart.splice(index,1);
                }
            });
             toast.success("Product is removed from cart!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.setItem('mycart',JSON.stringify(mycart));
               this.setState({
                            cart: mycart,
                        });
        }else{
         this.setState({
            loading: true,
        });
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('cart_id', id);

        axios({
            url: `${PUBLIC_URL}/deleteproduct`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,
        })
                .then(response => {

            toast.success("Product is removed from cart!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            if (response.data.status == 1) {

                axios({
                    url: `${PUBLIC_URL}/getcart`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + ciphertext },
                    data: {
                        user_id: 1,
                    }

                }).then(response => {

                    if (response.data.status == 1) {

                        this.setState({
                            cart: response.data.data,
                        });
                    }

                }).catch((error) => {

                });
            }

            this.setState({
                loading: false,
            });

        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });
        
        }
    }

    searchChange = (e) => {

        this.setState({
            search: e.target.value,
        });
    }

    changeLang= (e) =>{
        // this.setState({ fruit: e.target.value });

        // console.log(e.target.value);

        localStorage.setItem('language', e.target.value);

        this.setState({
              language:e.target.value
        });

        window.location.reload(true);


    }

    searchClick = () => {

        if (this.state.search != '') {
            this.props.navigate('/product-grid?search=' + this.state.search);
        }
    }

    render() {

        let { logedUser, cart, category, search, loading } = this.state;
    
        return (
            <>
                <SpinnerComponent open={loading} />
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 logo-block">
                                {/* <!-- Header Logo --> */}
                                <div className="logo"> <Link title="Citroen" to="/"><img alt="Citroen" src="/images/logo.png" className="myheader-logo"/> </Link> </div>
                                {/* <!-- End Header Logo -->  */}
                            </div>
                            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-3 hidden-xs category-search-form">
                                <div className="search-box">
                                    <form id="search_mini_form" action="/product-grid">
                                        {/* <!-- Autocomplete End code --> */}
                                        <ul className="categories-filter animate-dropdown">
                                            <li className="dropdown"> <a className="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0);">{languageList['category'][lang]} <b className="caret"></b></a>
                                                <ul className="dropdown-menu" role="menu">
                                                    {category && category.map((item, index) => {
                                                        return (
                                                            <li role="presentation" key={index}><Link role="menuitem" tabindex="-1" to={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`}>- {lang=='en'?item.category_name:item.multi_name? item.multi_name.arabic :item.category_name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                        </ul>
                                        <input id="search" type="text" name="search" onChange={this.searchChange} value={search} placeholder='Search here...' className="searchbox" maxlength="128" />
                                        <button type="button" title="Search" onClick={this.searchClick} className="search-btn-bg" id="submit-button"></button>
                                      
                                    </form>

                                </div>
                                
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1">
                            <select  className="form-select" style={{border:'0px',paddingTop:'27px',backgroundImage:'none'}}  onChange={this.changeLang} value={this.state.language}>
                                        <option value="en" >English</option>
                                        <option value="ar" >Arabic</option>
                                    </select>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12 card_wishlist_area">
                                <div className="mm-toggle-wrap">
                                    <div className="mm-toggle"><i className="fa fa-bars"></i><span className="mm-label">Menu</span> </div>
                                </div>
                               
                                
                                <div className="yujhs">
                              
                                    <div className="dropdown block-language-wrapper">
                                   
                                                                
                                      {/* <a href="#"> <img src="/images/arabia.png" alt="language" /> UAE </a> */}
                                       

                                    {name ? <p className='loggedUserName'> {name.length > 5 ?  `Hi ${name.substring(0, 5)}...`: name}</p> : <p></p>}
                                    </div>
                                    <div className="fl-links">
                                        <div className="no-js">
                                            {logedUser == false ? <a href="#" title="login now" className="clicker" data-toggle="modal" data-target="#myModalsignin"></a> :
                                                <>
                                                    <a href="#" title={name} className="clicker"></a>
                                                    <div className="fl-nav-links">
                                                        <ul className="links">
                                                            <li><Link to="/profile" title="My Account">{languageList['My_Account'][lang]}</Link></li>
                                                            <li><Link to="/wishlist" title="Wishlist">{languageList['Wishlist'][lang]}</Link></li>
                                                            <li><Link to="/orders" title="order">{languageList['orders'][lang]}</Link></li>
                                                            <li className="last"><a href="#" onClick={this.logout} title="Logout"><span>{languageList['Logout'][lang]}</span></a></li>
                                                        </ul>
                                                    </div>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="top-cart-contain">
                                    {/* <!-- Top Cart --> */}
                                    <div className="mini-cart">
                                        <div data-toggle="dropdown" data-hover="dropdown" className="basket dropdown-toggle">
                                            <Link to="/cart">
                                                <span className="cart_count">{cart.length}</span>
                                            </Link>
                                        </div>
                                        <div>
                                            <MiniCart cartAction={this.cartAction} cart={cart} />
                                        </div>
                                    </div>
                                    {/* <!-- Top Cart --> */}
                                    {/* <div id="ajaxconfig_info" style={{ display: 'block' }}> <a href="#/"></a>
                                        <input value="" type="hidden" />
                                        <input id="enable_module" value="1" type="hidden" />
                                        <input className="effect_to_cart" value="1" type="hidden" />
                                        <input className="title_shopping_cart" value="Go to shopping cart" type="hidden" />
                                    </div> */}
                                </div>
                                {/* <!-- thm wishlist -->  */}
                            </div>
                        </div>
                    </div>
                    <nav className="hidden-xs">
                        <div className="nav-container">
                            <div className="col-md-2 col-xs-12 col-sm-2">
                                <div className="mega-container visible-lg visible-md visible-sm">
                                    <div className="navleft-container">
                                        <div className="mega-menu-title">
                                            <h3><i className="fa fa-bars" aria-hidden="true"></i> {languageList['all_category'][lang]}</h3>
                                        </div>
                                        <div className="mega-menu-category">
                                            <ul className="nav">
                                                {category && category.map((item, index) => {

                                                    const chunkSize = 2;
                                                    const arr = item.subcategory;
                                                    let groups = [];

                                                    groups = arr.map((e, i) => {
                                                        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;

                                                    }).filter(e => {
                                                        return e;
                                                    });

                                                    if (item.subcategory.length != 0) {

                                                        return (

                                                            <li className="dropdown has-sub wide" key={index}> <a href="#" className="dropdown-toggle" data-toggle="dropdown"><i className="fa fa-long-arrow-right" aria-hidden="true"></i> <Link to={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`} style={{ color: '#000' }}>{lang=='en'?item.category_name:item.multi_name? item.multi_name.arabic :item.category_name}</Link></a>
                                                                <div className="dropdown-menu wrap-popup">
                                                                    <div className="popup">
                                                                        <div className="row">
                                                                            {groups && groups.map((item1, index1) => {

                                                                                return (
                                                                                    <div className="col-md-4 col-sm-6">
                                                                                        {item1 && item1.map((item2, index2) => {

                                                                                            if (index2 % 2 == 0) {
                                                                                                return (

                                                                                                    <>
                                                                                                        <h3><Link to={`/product-grid?subcategory=${item2.id}&innersubcategory=&category=&category_name=${item2.subcategory_name}`}> {lang=='en'?item2.subcategory_name:item2.multi_name? item2.multi_name.arabic :item2.subcategory_name}</Link></h3>
                                                                                                        <ul className="nav">

                                                                                                            {item2.inner_category && item2.inner_category.map((item3, index3) => {

                                                                                                                return (
                                                                                                                    <li key={index3}> <Link to={`/product-grid?innersubcategory=${item3.id}&subcategory=&category=&category_name=${item3.innersubcategory_name}`}><span> {lang=='en'?item3.innersubcategory_name:item3.multi_name? item3.multi_name.arabic :item3.innersubcategory_name}</span></Link> </li>
                                                                                                                );
                                                                                                            })}

                                                                                                        </ul>
                                                                                                        <br />
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                            else {

                                                                                                return (
                                                                                                    <>
                                                                                                        <h3><Link to={`/product-grid?subcategory=${item2.id}&innersubcategory=&category=&category_name=${item2.subcategory_name}`}>{item2.subcategory_name}</Link></h3>
                                                                                                        <ul className="nav">
                                                                                                            {item2.inner_category && item2.inner_category.map((item3, index3) => {

                                                                                                                return (
                                                                                                                    <li key={index3}> <Link to={`/product-grid?innersubcategory=${item3.id}&subcategory=&category=&category_name=${item3.innersubcategory_name}`}><span>{item3.innersubcategory_name}</span></Link> </li>
                                                                                                                );
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </>
                                                                                                );
                                                                                            }

                                                                                        })}

                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            {item.banner != null ? 
                                                                                <div className="col-md-4 has-sep hidden-sm">
                                                                                    <div className="custom-menu-right">
                                                                                        <div className="box-banner media">
                                                                                            <div className="add-right">
                                                                                                <Link to={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`}>
                                                                                                    <img src={item.banner.image_url} alt="cat" />
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>:
                                                                            ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li className="nosub" key={index}><a href="#"><i className="fa fa-long-arrow-right" aria-hidden="true"></i> {item.category_name} </a></li>
                                                        );
                                                    }
                                                })}
                                                <li className="nosub custom-offer"><Link to="/product-grid"><i className="fa fa-long-arrow-right"></i>{languageList['View_all'][lang]}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- features box --> */}
                            <div className="our-features-box hidden-xs">
                                <div className="features-block">
                                    <div className="col-lg-9 col-md-10 col-xs-12 col-sm-10 offer-block">
                                     <Link to={'/'} className="mouse-over ">{languageList['home'][lang]}</Link>
                                        {category && category.map((item, index) => {
                                             let caturl=`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`;
                                             let active='';
                                             let cpath=decodeURI(window.location.pathname + window.location.search + window.location.hash);
                                             if(caturl===cpath)
                                             {
                                                active='active'; 
                                             }
                                            if(index < 5)
                                                return <Link to={caturl} key={index} className={"mouse-over "+active}>{lang=='en'? item.category_name : item.multi_name? item.multi_name.arabic :item.category_name}</Link>

                                        })}
                                        
                                        {/* <span>Free Shipping</span>
                                        <span>Hot Deals</span> */}
                                        {((this.props.hasOwnProperty('factoryOutlet'))&&(this.props.factoryOutlet==1))?
                                             (<Link to={`/product-grid`} id="factory-outlet-button" onClick={this.forceUpdate} className="responsive-factory-outlet-btn"></Link>):
                                                     ( <Link to={`/factory_outlet`} id="factory-outlet-button"  className="responsive-factory-outlet-btn" onClick={this.forceUpdate} ></Link>) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>

                <Login />
                <Register />
                <ForgotPassword />
                <MobileView>
                {/* <DownloadMobile/> */}
                </MobileView>
            </>
        );
    }
}

export default withRouter(Header);