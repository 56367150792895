import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class ProductItem extends Component {

    constructor(props) {
        super(props);

    }

    addToCart = () => {
      let product = this.props.product;
      
        let taxvalue = 0;
        let proPrice=product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price;
        if(product.tax_type === 'percent'){

            taxvalue =proPrice * (product.tax / 100);

        }
        else{
            taxvalue = product.tax;
        }
    let cart = JSON.parse(localStorage.getItem('mycart'));
    if(!cart)
    {
       cart=[];
    }
        if ((ciphertext === '')||(ciphertext==='null'))
        {
        let added=false;
      cart.forEach(function(cartItem,index){
          if(cartItem.product_id===product.id)
          {
              cart[index].qty++;
              added=true;
          }
      });
      if(!added)
      {
        let NewItem={};
        NewItem.user_id=1;
        NewItem.vendor_id=product.vendor_id;
        
        NewItem.product_id=product.id;
        NewItem.product_name=product.product_name;
        NewItem.qty=1;
        NewItem.price=proPrice;
        NewItem.discount_amount=0;
         NewItem.tax=taxvalue;
        NewItem.shipping_cost=product.shipping_cost;
        NewItem.variation='null';
        NewItem.attribute='null';
        NewItem.variations=[];
        NewItem.image_url=product.productimage.image_url;
        NewItem.productObject=product;
          cart.push(NewItem);
         toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
           localStorage.setItem('mycart',JSON.stringify(cart));    
          this.props.cartEvent();

      }
      
       }else{
      


        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', product.id);
        formData.append('vendor_id', product.vendor_id);
        formData.append('product_name', product.product_name);
        formData.append('qty', 1);
        formData.append('price', product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price);
        formData.append('image', product.productimagewithout_url.image_url);
        formData.append('discount_amount', 0);
        formData.append('tax', taxvalue);
        formData.append('shipping_cost', product.shipping_cost);

        axios({
            url: `${PUBLIC_URL}/addtocart`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).
                then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.props.cartEvent();
            }else{
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

        }).catch((error) => {
          
         });
    }
    }

    addToWishlist = (id) => {
        if ((ciphertext === '')||(ciphertext==='null'))
        {
          toast.error("Please login before added to wishlist!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            return;
        }
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', id);
        
        axios({
            url: `${PUBLIC_URL}/addtowishlist`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to wishlist!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }).catch((error) => {});
    }

    render() {

        let { iteration, product } = this.props;

        let rating = product.rattings.length > 0 ? product.rattings[0].avg_ratting : 0;

        return (
            <>
                <li className="item col-lg-4 col-md-4 col-sm-4 col-xs-6">
                    <div className="item-inner">
                        <div className="item-img">
                        
                            <div className="item-img-info">
                            <Link className="product-image" title={product.product_name} to={`/product-details?product=${product.id}`}>
                            <div style={{height: '200px', width: '200px',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage:`url("${product.productimage?product.productimage.image_url:''}")`}} ></div>

                            </Link>

                                {/* <div className="sale-label sale-top-right">- 40% sale</div> */}
                                <div className="box-hover">
                                    <ul className="add-to-links">
                                        <li><Link className="link-quickview" to={`/product-details?product=${product.id}`}></Link> </li>
                                        <li><a onClick={() => this.addToWishlist(product.id)} className="link-wishlist" href="javascript:void(0);"></a> </li>
                                        {/* <li><a className="link-compare" href="compare.html"></a> </li> */}
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                        <div className="item-info">
                            <div className="info-inner">
                                <div className="item-title">
                                    <Link title={product.product_name} to={`/product-details?product=${product.id}`}>{lang== 'en'? product.product_name ? product.product_name.substring(0, 35) + '...' : '':product.multi_name ? product.multi_name.arabic.substring(0, 35) + '...' : product.product_name}</Link>
                                </div>
                                <div className="brand">{product.brand_name}</div>
                                <div className="star-rating">
                                    <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                                </div>
                                <div className="item-content">
                                    <div className="item-price">
                                        <div className="price-box">
                                            <span className="regular-price">
                                                <span className="price">AED {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? product.product_price : product.discounted_price}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="action">
                                        <button onClick={this.addToCart} className="button btn-cart" type="button" title="" data-original-title="Add to Cart">
                                            <i className="fa fa-shopping-basket"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </>
        );
    }
}

export default ProductItem;